import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion, useCycle } from 'framer-motion';

import { Link } from 'gatsby';
import { toSlug, toTitle } from '../../../utils/formatters';

const Header = ( { logo, opacity, navigation, contact } ) => {
	const [ isActive, setIsActive ] = useState( false );
	const [ sticky, setSticky ] = useState( false );
	const [ isSticky, toggleIsSticky ] = useCycle( false, true );

	const navRef = useRef();

	useEffect( () => {
		const scrollListener = () => {
			window.scrollY === 0 && setSticky( false );
			window.scrollY > navRef.current.offsetHeight
				? !sticky && setSticky( true )
				:	sticky && setSticky( false );
		};

		window.addEventListener( 'scroll', scrollListener );

		return () => window.removeEventListener( 'scroll', scrollListener );
	}, [] );

	useEffect( () => {
		const stickySetter = () => {
			toggleIsSticky();
		};

		return () => stickySetter();
	}, [ sticky ] );

	return (
		<motion.header
			initial={ sticky }
			animate={ isSticky ? 'sticky' : 'default' }
			variants={{
				default: {
					backgroundColor:
					opacity
						? contact
							? 'rgba(4, 26, 47, 0.1)'
							: 'rgba(4, 26, 47, 0.75)'
						: '#041a2f'
				},
				sticky: { backgroundColor: '#041a2f' }
			}}
			transition={{ duration: 0.3, ease: 'easeInOut' }}
			ref={navRef}
			className="siteHeader"
		>
			<div className={`navWrapper ${ contact ? 'text-primary-black' : '' }`}>
				<Link to="/">
					<img
						alt="Kinetic IQ Logo"
						className="header_logo"
						src={ logo }
					/>
				</Link>
			</div>
		</motion.header>
	);
};

Header.defaultProps = { opacity: true };

Header.propTypes = {
	logo: PropTypes.string,
	opacity: PropTypes.bool,
	navigation: PropTypes.object,
	contact: PropTypes.bool
};

export default Header;
