import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { PopupButton } from '@typeform/embed-react';
import Button from '../../Button';
import { bannerHeaderVar } from '../../../utils/framerVariants';

const LandingPEO = ( { headings } ) => {
	const [ index, setIndex ] = useState( 0 );

	useEffect( () => {
		const interval = setInterval( () => {
			setIndex( prev => prev < headings.length - 1 ? prev + 1 : 0 );
		}, 5000 );

		return () => {
			clearInterval( interval );
		};
	}, [ headings.length, setIndex ] );

	return (
		<div>
			<section id="hero-5" className="bg--scroll hero-section">
				<div className="container">
					<div className="row d-flex align-items-center">

						<div className="col-md-6">
							<div className="hero-5-txt wow fadeInRight">

								<h2 className="s-58 w-700">You Support America's Small Businesses</h2>
								<h2 className="s-40 w-700">We Support You</h2>

								<p className="p-lg">Empowering PEOs to efficiently manage their sales cycles,
                                    reduce manual workload, and offer clients a smooth, digital
                                    experience from lead to renewal.
								</p>

								<a href="#form" className="btn r-04 btn--theme hover--theme">Get started Now</a>

							</div>
						</div>

						<div className="col-md-6">
							<div className="hero-5-img wow fadeInLeft">
								<img className="img-fluid" src="../media/PEO_1.png" alt="hero-image"/>
							</div>
						</div>

					</div>
				</div>

				<div className="wave-shape-bottom">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 170"><path fillOpacity="1" d="M0,160L120,160C240,160,480,160,720,138.7C960,117,1200,75,1320,53.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
				</div>
			</section>
			<section id="hero-5-mobile" className="bg--scroll hero-section">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-md-6">
							<div className="hero-5-mobile-logo">
								<img className="img-fluid" src="../media/tl_kiq.png" alt="hero-image"/>
							</div>
							<div className="hero-5-img wow fadeInLeft">
								<img className="img-fluid" src="../media/PEO_1.png" alt="hero-image"/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="hero-5-txt wow fadeInRight">

								<h2 className="s-58 w-700">You Support Small Businesses</h2>
								<h3 className="s-30 w-700">We Support You</h3>

								<p className="p-lg">Empowering PEOs to efficiently manage their sales cycles,
                                    reduce manual workload, and offer clients a smooth, digital
                                    experience from lead to renewal.
								</p>

								<a href="#form" className="btn r-04 btn--theme hover--theme">Get started Now</a>

							</div>
						</div>
					</div>
				</div>

				<div className="wave-shape-bottom">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 170"><path fillOpacity="1" d="M0,160L120,160C240,160,480,160,720,138.7C960,117,1200,75,1320,53.3L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
				</div>
			</section>

			<section id="features-13" className="shape--bg shape--white-500 pt-100 features-section division">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-md-7 order-last order-md-2">
							<div className="fbox-13-wrapper wow fadeInRight">
								<div className="row">
									<div className="col-md-6">
										<div id="fb-12-1" className="fbox-12 bg--white-100 block-shadow r-12 mb-30">
											<div className="fbox-ico ico-50">
												<div className="shape-ico color--theme">
													<img src="../media/PEO_ICON_5.svg" alt="Your SVG Image" />
												</div>
											</div>
											<div className="fbox-txt">
												<h5 className="s-19 w-700">Automated Sales Management</h5>
												<p>An efficient sales management system streamlines processes, automates RFPS, proposals, and documents, tracks leads, and empowers your sales team.</p>
											</div>
										</div>
										<div id="fb-12-2" className="fbox-12 bg--white-100 block-shadow r-12">
											<div className="fbox-ico ico-50">
												<div className="shape-ico color--theme">
													<img src="../media/PEO_ICON_6.svg" alt="Your SVG Image" />
												</div>
											</div>
											<div className="fbox-txt">
												<h5 className="s-19 w-700">Automated Benefits Management</h5>
												<p>A streamlined and user-friendly benefits renewal process will cut time and ensure accuracy for this critical task.</p>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div id="fb-12-3" className="fbox-12 bg--white-100 block-shadow r-12 mb-30">
											<div className="fbox-ico ico-50">
												<div className="shape-ico color--theme">
													<img src="../media/PEO_ICON_7.svg" alt="Your SVG Image" />
												</div>
											</div>
											<div className="fbox-txt">
												<h5 className="s-19 w-700">Automated Client Management</h5>
												<p>Ensuring a smooth and error-free onboarding process while meeting compliance requirements can be time-consuming and demanding without a system built for you.</p>
											</div>
										</div>
										<div id="fb-12-4" className="fbox-12 bg--white-100 block-shadow r-12">
											<div className="fbox-ico ico-50">
												<div className="shape-ico color--theme">
													<img src="../media/PEO_ICON_8.svg" alt="Your SVG Image" />
												</div>
											</div>
											<div className="fbox-txt">
												<h5 className="s-19 w-700">Business Analytics</h5>
												<p>By integrating with other systems, we can provide a full-picture view of your data and provide insights to help you make informed decisions.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-5 order-first order-md-2">
							<div className="txt-block left-column wow fadeInLeft">
								<span className="section-id">TRANSFORM YOUR BUSINESS</span>
								<h2 className="s-46 w-700">YOUR SUCCESS IS OUR MISSION</h2>
								<p>We understand the challenges faced by PEO companies as they navigate the intricacies of lead management, client onboarding, and benefits tracking. That's why we have honed our expertise to provide comprehensive, tailored solutions that empower your team to excel.</p>
								<p>We believe that every PEO company is unique, and so are its needs. We don't believe in one-size-fits-all solutions. Instead, we take the time to understand your specific requirements, challenges, and goals. With this knowledge, we craft customized strategies that drive results and maximize your ROI.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<div style={{ display: 'none' }} id="statistic-1" className="py-100 statistic-section division">
				<div className="container">
					<div className="statistic-1-wrapper">
						<div className="row justify-content-md-center row-cols-1 row-cols-md-3">
							<div className="col">
								<div id="sb-1-1" className="wow fadeInUp">
									<div className="statistic-block">
										<div className="statistic-block-digit text-center">
											<h2 className="s-46 statistic-number"><span className="count-element">30</span>k</h2>
										</div>
										<div className="statistic-block-txt color--grey">
											<p className="p-md">Employees Served</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col">
								<div id="sb-1-2" className="wow fadeInUp">
									<div className="statistic-block">
										<div className="statistic-block-digit text-center">
											<h2 className="s-46 statistic-number"><span className="count-element">500</span></h2>
										</div>
										<div className="statistic-block-txt color--grey">
											<p className="p-md">Clients Served</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col">
								<div id="sb-1-3" className="wow fadeInUp">
									<div className="statistic-block">
										<div className="statistic-block-digit text-center">
											<h2 className="s-46 statistic-number">
												<span className="count-element">20</span>.<span>%</span>
											</h2>
										</div>
										<div className="statistic-block-txt color--grey">
											<p className="p-md">Average Annual Growth</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<section id="banner-13" className="pt-100 banner-section">
				<div className="container">

					<div className="banner-13-wrapper bg--01 bg--scroll r-16 block-shadow">
						<div className="banner-overlay">
							<div className="row d-flex align-items-center">

								<div className="col-md-7">
									<div className="banner-13-txt color--white">

										<h2 className="s-46 w-700">FREE E-BOOK</h2>
										<h3 className="s-42 w-700">TRANSFORMING PEO OPERATIONS</h3>

										<p className="p-lg">A Roadmap to Streamlined Sales and Client Management</p>
										<PopupButton id="XDuD7NSO" hidden={{ ebook: 'true' }} style={{ fontSize: 20 }} className="eb_button">
										Get started - it's free
										</PopupButton>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section style={{ display: 'none' }} id="lnk-2" className="pt-100 ws-wrapper content-section">
				<div className="container">
					<div className="bc-5-wrapper bg--02 hidd bg--scroll r-16">
						<div className="section-overlay">
							<div className="row justify-content-center">
								<div className="col-md-11 col-lg-9">
									<div className="section-title wow fadeInUp mb-60">
										<h2 className="s-40 w-700">A unified system simplifies key functions, reducing complexity and improving performance.</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="features-11" className="py-70 features-section division">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-10 col-lg-9">
							<div className="section-title mb-70">
								<h2 className="s-50 w-700">Tailor-Made Solutions for Your Business</h2>
								<p className="s-21 color--grey">KINETIC IQ is ready to be your strategic partner in unleashing your full potential. Together, we can take your PEO to new heights and navigate the ever-changing landscape with confidence.</p>
							</div>
						</div>
					</div>
					<div className="fbox-wrapper">
						<div className="row row-cols-1 row-cols-md-2 rows-3">
							<div className="col">
								<div className="fbox-11 fb-1 wow fadeInUp">
									<div className="fbox-ico-wrap">
										<div className="fbox-ico ico-50">
											<div className="shape-ico color--theme">
												<img src="../media/PEO_ICON_1.svg" alt="Your SVG Image" />
											</div>
										</div>
									</div>
									<div className="fbox-txt">
										<h6 className="s-22 w-700">Unlocked Potential</h6>
										<p>From lead acquisition to client retention, we streamline your processes to save time, effort, and resources. Our solutions provide your sales agents with a competitive advantage, while your clients experience unmatched satisfaction in their journey with your company.</p>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="fbox-11 fb-2 wow fadeInUp">
									<div className="fbox-ico-wrap">
										<div className="fbox-ico ico-50">
											<div className="shape-ico color--theme">
												<img src="../media/PEO_ICON_2.svg" alt="Your SVG Image" />
											</div>
										</div>
									</div>
									<div className="fbox-txt">
										<h6 className="s-22 w-700">Empowered Sales Force</h6>
										<p>From seamlessly integrating your CRM to creating efficient RFP portals, our solutions empower your sales agents to focus on what they do best – building relationships and closing deals.</p>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="fbox-11 fb-3 wow fadeInUp">
									<div className="fbox-ico-wrap">
										<div className="fbox-ico ico-50">
											<div className="shape-ico color--theme">
												<img src="../media/PEO_ICON_3.svg" alt="Your SVG Image" />
											</div>
										</div>
									</div>
									<div className="fbox-txt">
										<h6 className="s-22 w-700">Data-Driven Insight</h6>
										<p>Harnessing the power of data is at the heart of our approach. We gather actionable insights from various sources, enabling your team to make informed decisions about tier changes, benefit plans, and more. Our solutions transform raw data into strategic advantages, giving your company the edge it deserves.</p>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="fbox-11 fb-4 wow fadeInUp">
									<div className="fbox-ico-wrap">
										<div className="fbox-ico ico-50">
											<div className="shape-ico color--theme">
												<img src="../media/PEO_ICON_4.svg" alt="Your SVG Image" />
											</div>
										</div>
									</div>
									<div className="fbox-txt">
										<h6 className="s-22 w-700">Peace of Mind</h6>
										<p>We recognize the critical importance of data security and compliance. Rest assured, your sensitive information is safeguarded at every step of the process. Our commitment to confidentiality and adherence to industry regulations ensures that your trust in us is well-placed.</p>
									</div>
								</div>
							</div>
							<div className="col" style={{ display: 'none' }}>
								<div className="fbox-11 fb-5 wow fadeInUp">
									<div className="fbox-ico-wrap">
										<div className="fbox-ico ico-50">
											<div className="shape-ico color--theme">
												<img src="../media/PEO_ICON_3.svg" alt="Your SVG Image" />
											</div>
										</div>
									</div>
									<div className="fbox-txt">
										<h6 className="s-22 w-700">Brand Design Identity</h6>
										<p>Porta semper lacus cursus feugiat primis ultrice ligula risus ociis auctor and tempus feugiat impedit felis cursus auctor augue mauris blandit ipsum</p>
									</div>
								</div>
							</div>
							<div className="col" style={{ display: 'none' }}>
								<div className="fbox-11 fb-6 wow fadeInUp">
									<div className="fbox-ico-wrap">
										<div className="fbox-ico ico-50">
											<div className="shape-ico color--theme">
												<img src="../media/PEO_ICON_4.svg" alt="Your SVG Image" />
											</div>
										</div>
									</div>
									<div className="fbox-txt">
										<h6 className="s-22 w-700">SEO & SMM Services</h6>
										<p>Porta semper lacus cursus feugiat primis ultrice ligula risus ociis auctor and tempus feugiat impedit felis cursus auctor augue mauris blandit ipsum</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<hr className="divider"></hr>

			<section id="integration" className="bg--white-300 py-100 ct-02 content-section division">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-md-6">
							<div className="img-block left-column wow fadeInRight">
								<img className="img-fluid" src="../media/PEO_3.png" alt="content-image"/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="txt-block right-column wow fadeInLeft">
								<span className="section-id color--grey">Integration First Design</span>
								<h2 className="s-46 w-700">We won’t reinvent the wheel</h2>
								<ul className="simple-list">
									<li className="list-item">
										<p>Our application solutions are engineered to seamlessly integrate with the tools you use every day, eliminating unnecessary features and saving you recurring subscription fees.</p>
									</li>
									<li className="list-item">
										<p>By prioritizing integration, we reduce development costs and streamline your workflow, allowing you to focus on what matters most.</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="lnk-1" className="pt-100 ct-02 content-section division">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-md-6">
							<div className="txt-block left-column wow fadeInRight">
								<span className="section-id">Tailor-Made Solution</span>
								<h2 className="s-46 w-700">Unlock Your Potential</h2>
								<p>At Kinetic IQ, we are passionate subject matter experts in the dynamic world of PEO sales and business management and we believe that every PEO company is unique, and so are its needs. We don't believe in one-size-fits-all solutions. Instead, we take the time to understand your specific requirements, challenges, and goals. With this knowledge, we craft customized strategies that drive results and maximize your ROI. From lead acquisition to client retention, we streamline your processes to save time, effort, and resources. Our solutions provide your sales agents with a competitive advantage, while your clients experience unmatched satisfaction in their journey with your company.</p>
								<p>Kinetic IQ is ready to be your strategic partner in unleashing your full potential. Together, we can take your PEO company to new heights and navigate the ever-changing landscape with confidence.</p>
							</div>
						</div>
						<div className="col-md-6">
							<div className="img-block right-column wow fadeInLeft">
								<img className="img-fluid" src="../media/PEO_2.png" alt="content-image"/>
							</div>
						</div>
					</div>
				</div>
			</section>

		</div>

	);
};

LandingPEO.propTypes = { headings: PropTypes.array };

export default LandingPEO;
